import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';

import Loader from '../components/FullScreenLoader';
import Notification from '../components/Notification';
import { SIDEBARSHOW } from '../constants/app';

export const AppContext = createContext();

function AppProvider({ children }) {
  const notificationRef = useRef();
  const [loading, setLoading] = useState(false);
  const [sidebarShow, setSidebarShow] = useState('opened');
  const [navbarTogglerOpen, setNavbarTogglerOpen] = useState(true);

  const toggleSidebar = useCallback(() => {
    setSidebarShow((prevState) => {
      localStorage.setItem(SIDEBARSHOW, prevState === 'opened' ? 'closed' : 'opened');
      return prevState === 'opened' ? 'closed' : 'opened';
    });
  }, []);

  const minimizeSidebar = useCallback(() => {
    setSidebarShow(() => {
      localStorage.setItem(SIDEBARSHOW, 'minimized');
      return 'minimized';
    });
  }, []);

  useEffect(() => {
    setSidebarShow(localStorage.getItem(SIDEBARSHOW) || 'opened');
  }, []);

  const value = useMemo(
    () => ({
      loading,
      sidebarShow,
      toggleSidebar,
      minimizeSidebar,
      navbarTogglerOpen,
      notificationRef,
      setNavbarTogglerOpen,
      setLoading,
    }),
    [
      loading,
      sidebarShow,
      toggleSidebar,
      minimizeSidebar,
      navbarTogglerOpen,
      notificationRef,
    ],
  );

  return (
    <AppContext.Provider value={value}>
      <Loader loading={loading} />
      <Notification ref={notificationRef} />
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
