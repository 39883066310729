import React from 'react';
import { Routes, Route } from 'react-router-dom';

const LayoutAdmin = React.lazy(() => import('../layouts/user'));
const Home = React.lazy(() => import('../pages/Home'));
const Agendamentos = React.lazy(() => import('../pages/Agendamentos'));
const Agendamento = React.lazy(() => import('../pages/Agendamento'));
const CalendarioAgendamentos = React.lazy(() => import('../pages/CalendarioAgendamentos'));
const Faturas = React.lazy(() => import('../pages/Faturas'));
// const Reembolsos = React.lazy(() => import('../pages/Reembolsos'));
const Profile = React.lazy(() => import('../pages/Profile'));

function AppUserRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LayoutAdmin />}>
        <Route path="/" element={<Home />} />
        <Route path="/agendamentos" element={<Agendamentos />} />
        <Route path="/agendamento" element={<Agendamento />} />
        <Route path="/agendamento/:id" element={<Agendamento />} />
        <Route path="/calendario-agendamentos" element={<CalendarioAgendamentos />} />
        <Route path="/faturas" element={<Faturas />} />
        {/* <Route path="/reembolsos" element={<Reembolsos />} /> */}
        <Route path="/perfil" element={<Profile />} />
      </Route>
    </Routes>
  );
}

export default AppUserRoutes;
